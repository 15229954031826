import React from 'react'
import Navbr from './Navbr'
import Steps from './Steps'

export default function Header({ title, step, errorMessage }) {
    return (
        <>
            <Navbr
                title={title}
                errorMessage={errorMessage}
            />
            <div className={errorMessage
                ? "mt-[116px]"
                : "mt-[68px]"
            }></div>
            <Steps step={step} />
        </>
    )
}
