import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import * as yup from "yup"
import { passwordSchema } from '../Validation/createPasswordSchema'

export default function Create() {
    const navigate = useNavigate()
    const [password, setPassword] = useState("")
    const [confirmPassword, setConfirmPassword] = useState("")
    const [isDisable, setIsDisable] = useState(true)
    useEffect(() => {
        passwordSchema.isValid({ password, confirmPassword })
            .then(res => {
                setIsDisable(res)
                return
            })
    }, [password, confirmPassword])

    return (
        <div className='w-screen h-screen p-5'>
            <div className='h-full flex items-center justify-center overflow-x-hidden overflow-y-auto'>
                <div className='w-[285px] mt-10 flex flex-col gap-5'>
                    <h1 className='text-lg text-center select-none'>Create a password</h1>

                    <p className='text-white/50 text-center select-none text-xs'>You will need this to unlock your wallet</p>

                    <input
                        type="password"
                        className='bg-[rgb(42,38,38)] w-full py-[11px] px-[15.2px] rounded-[5px] h-[46.5px] text-sm text-white border-0 outline-0 focus-visible:ring-0 box-border'
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    <input
                        type="password"
                        placeholder='Confirm Password'
                        className='bg-[rgb(42,38,38)] w-full py-[11px] px-[15.2px] rounded-[5px] h-[46.5px] text-sm text-white border-0 outline-0 focus-visible:ring-0 box-border'
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                    />

                    <button
                        className='w-full min-h-9 h-10 rounded px-4 disabled:opacity-50 disabled:cursor-not-allowed text-sm bg-[rgb(253,224,71)] text-black select-none'
                        onClick={() => navigate('/createphrase')}
                        disabled={!isDisable}
                    >
                        Continue
                    </button>
                </div>
            </div>
        </div>
    )
}
