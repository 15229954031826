import React from 'react'
import { useLocation } from 'react-router-dom'

const stepname = ['/newpage', '/import', '']
const stepname1 = ['/createphrase', '/import', '']

export default function Steps({ step }) {
    const { pathname } = useLocation()

    return (
        <div className='flex items-center justify-center gap-2 px-6'>
            {step && Array.from({ length: step }, (item, index) => (
                <button
                    key={index}
                    className={`text-sm ${(stepname[index] === pathname) || (stepname1[index] === pathname)
                        ? 'text-[rgb(234,194,73)] border-b-2 border-b-[rgb(234,194,73)]'
                        : 'text-white/50'}`}
                    disabled={(stepname[index] !== pathname) || (stepname1[index] === pathname)}
                >
                    Step {index + 1}
                </button>
            ))}

            {/* {step && step.slice(0, 3).map((item, index) => (
            ))} */}
        </div>
    )
}
