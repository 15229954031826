import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import NewPage from './pages/NewPage';
import Create from './pages/Create';
import Import from './pages/Import'
import CreatePhrase from './pages/CreatePhrase';

const router = createBrowserRouter([
  {
    path: '/',
    element: <App />
  },
  {
    path: '/newpage',
    element: <NewPage />
  },
  {
    path: '/import',
    element: <Import />
  },
  {
    path: '/newpage',
    element: <NewPage />
  },
  {
    path: '/create',
    element: <Create />
  },
  {
    path: '/createphrase',
    element: <CreatePhrase />
  },
])

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

reportWebVitals();
