import React, { useEffect, useState } from 'react'
import * as bip39 from "@scure/bip39"
import { wordlist } from '@scure/bip39/wordlists/english'
import Header from '../components/Header'
import Message from '../components/Message'
import SuccessfulModal from '../components/SuccessfulModal'

export default function CreatePhrase() {
    const mnemonic = bip39.generateMnemonic(wordlist)
    const [phrases, setPhrases] = useState([])
    const [isChecking, setIsChecking] = useState(false)
    const [successfulMessage, setSuccessfulMessage] = useState(null)

    useEffect(() => {
        if (mnemonic) {
            setPhrases(mnemonic.split(" "))
            return
        }
    }, [])

    const onContinue = () => {
        setSuccessfulMessage(true)
    }

    return (<>
        {successfulMessage && <SuccessfulModal
            title={'Wallet Created successfully'}
            onClose={() => setSuccessfulMessage(null)}
        />}

        <Header
            step={2}
            title={"Create a new HD Wallet"}
        />

        <div className='px-[10px] mt-3 flex flex-col items-center gap-5 justify-start'>
            <h3 className='text-lg'>
                Secret Recovery Phrase
            </h3>
            <p className='text-sm text-[rgb(255,123,33)]'>
                This phrase is the ONLY way to recover your wallet. Do NOT share it with anyone!
            </p>
            <button className='w-full flex items-center justify-center gap-2 text-white/50'>
                <img src="/copy-solid.svg" className='w-[14px] h-[14px] fill-white/50' alt="" />
                <span>Copy to clipboard</span>
            </button>

            <div className='grid grid-cols-2 gap-2 max-w-[550px] w-full'>
                {Array.from({ length: 12 }, (item, index) => (
                    <div
                        key={index}
                        className='flex  gap-2 text-sm'
                    >
                        <p className='w-10'>{index + 1}.</p>
                        <p className='py-1 px-3 w-full text-center rounded-md bg-[rgb(42,38,38)]'>
                            {phrases[index]}
                        </p>
                    </div>
                ))}
            </div>

            <button
                onClick={() => setIsChecking(!isChecking)}
                className='cursor-pointer flex items-center justify-center gap-2'
            >
                <div className={`w-[15px] h-[15px] relative rounded-sm after:absolute after:left-1/2 after:-translate-x-[10%] after:top-1/2 after:-translate-y-[60%] after:h-[14px] after:w-2 after:border-[3px] after:border-white after:border-t-0 after:border-l-0 after:rotate-45 after:opacity-0 ${isChecking
                    ? "bg-[#eaca44] after:opacity-100"
                    : "border border-white/20 bg-[rgb(42,38,38)]"}
                `}></div>

                <span>
                    I saved My Secret Recovery Phrase
                </span>
            </button>

            <div className='h-[80px]'></div>
            <div className='fixed bottom-0 w-full left-0 px-[10px] pt-[10px] pb-[20px] bg-[rgb(28,25,25)]'>
                <button
                    className='w-full rounded bg-[rgb(253,224,71)] text-black px-3 min-h-9 disabled:opacity-50 disabled:cursor-not-allowed'
                    type='submit'
                    onClick={() => onContinue()}
                    disabled={!isChecking}
                >
                    Continue
                </button>
            </div>
        </div>
    </>)
}
