import React from 'react'
import { FaArrowLeft } from 'react-icons/fa'

export default function SuccessfulModal({ onClose, title }) {
    return (
        <div className='fixed z-30 w-full h-screen top-0 left-0 bg-[rgb(42,38,38)] flex items-center justify-center'>
            <div className='relative w-[450px] px-5 pt-[68px] pb-12 flex flex-col items-center justify-center gap-8 bg-[rgb(28,25,25)] rounded-lg'>
                <button
                    className='absolute top-5 left-[20px] flex gap-2 items-center text-white no-underline'
                    onClick={() => onClose()}
                >
                    <FaArrowLeft color='white' />
                    <span>Back</span>
                </button>
                <img
                    className='w-28 h-28'
                    src="/checked.svg"
                    alt=""
                />
                <h3 className='text-2xl font-medium'>
                    {title}
                </h3>
                <button
                    className='mt-5 w-full rounded bg-[rgb(253,224,71)] text-black px-3 min-h-9'
                    type='submit'
                    onClick={() => onClose()}
                >
                    Continue
                </button>
            </div>
        </div>
    )
}
